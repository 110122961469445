$(document).ready(function(){
    "use strict";

    /**
     * create random token
     */
    function createToken(){
        "use strict";

        var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
        var token = "";
        for(var x = 0; x < 32; x++){
            var i = Math.floor(Math.random() * chars.length);
            token += chars.charAt(i);
        }
        return token;
    }

    /*
     * COURSE ATTENDEES
     */
    $(".addAttendeeButtons button").on('click', function(e){

        e.preventDefault();

        var token     = createToken();
        var container = $("#attendeesRowsWrapper");
        var type      = $(this).attr('data-type');
        
        container.removeClass('hidden');
        $(".addAttendeesToCart").removeClass('hidden');
        $(".continueAdding").removeClass('hidden');
        $(".attendeesHeaderLabelsWrapper").removeClass('hidden');
        $(".addSomeAttendeesHeader").addClass('hidden');

        if(type == 'doctor'){

            container.append('<div class="attendeeFormRow">' +
                              '<div>' + 
                                   '<div class="attendeeRowLabel">' + $(this).attr('data-label') + '</div>' + 
                                  '<input type="hidden" name="entry_id[' + token + ']" value="' + $(this).attr('data-entry-id') + '" />' +
                                  '<input type="hidden" name="quantity[' + token + ']" value="1" />' +
                                  '<div class="formRowSplit">' +
                                      '<div class="required">Name</div>' +
                                      '<div><input type="text" name="item_options[' + token + '][name]" value="" style="width:100%;" maxlength="100" required="required"></div>' +
                                  '</div>' + 
                                  '<div class="formRowSplit">' +
                                      '<div class="required">Specialty</div>' +
                                      '<div class="styledSelect">' +
                                            '<select name="item_options[' + token + '][specialty]" required="required">' + 
                                                '<option value=""></option>' +
                                                '<option value="General">General</option>' +
                                                '<option value="Endodontist">Endodontist</option>' +
                                                '<option value="Orthodontist">Orthodontist</option>' +
                                                '<option value="Oral Surgeon">Oral Surgeon</option>' +
                                                '<option value="Periodontist">Periodontist</option>' +
                                                '<option value="Prosthodontist">Prosthodontist</option>' +
                                                '<option value="Other">Other</option>' +
                                            '</select>' + 
                                        '</div>' +
                                  '</div>' + 
                              '</div>' + 
                              '<div>' +
                                '<select name="item_options[' + token + '][product_pricing]"><option value="full_amount">' + $(this).attr('data-full_amount') + '</option><option value="deposit">' + $(this).attr('data-deposit') + '</option></select>' + 
                              '</div>' + 
                              '<div><button data-container="' + $(this).attr('data-container') + '" class="btnDel"><img src="/assets/graphics/svg/fa/remove.svg" width="16" height="16" alt="Remove" style="width: 16px;height: 16px;"></button></div>' + 
                           '</div>');
        } else if(type == 'spouse') {

            container.append('<div class="attendeeFormRow">' +
                                  '<div>' + 
                                        '<div class="attendeeRowLabel">' + $(this).attr('data-label') + '</div>' +
                                      '<input type="hidden" name="entry_id[' + token + ']" value="' + $(this).attr('data-entry-id') + '" />' +
                                      '<input type="hidden" name="quantity[' + token + ']" value="1" />' +
                                      '<div class="formRowSplit">' +
                                          '<div class="required">Name</div>' +
                                          '<div><input type="text" name="item_options[' + token + '][name]" value="" style="width:100%;" maxlength="100" required="required"></div>' +
                                      '</div>' + 
                                  '</div>' + 
                                  '<div>' +
                                    '<select name="item_options[' + token + '][product_pricing]"><option value="full_amount">' + $(this).attr('data-full_amount') + '</option><option value="deposit">' + $(this).attr('data-deposit') + '</option></select>' + 
                                  '</div>' + 
                                  '<div><button data-container="' + $(this).attr('data-container') + '" class="btnDel"><img src="/assets/graphics/svg/fa/remove.svg" width="16" height="16" alt="Remove" style="width: 16px;height: 16px;"></button></div>' + 
                               '</div>');
        } else if(type == 'staff') {

            container.append('<div class="attendeeFormRow">' +
                                  '<div>' + 
                                        '<div class="attendeeRowLabel">' + $(this).attr('data-label') + '</div>' +
                                      '<input type="hidden" name="entry_id[' + token + ']" value="' + $(this).attr('data-entry-id') + '" />' +
                                      '<input type="hidden" name="quantity[' + token + ']" value="1" />' +
                                      '<div class="formRowSplit">' +
                                          '<div class="required">Name</div>' +
                                          '<div><input type="text" name="item_options[' + token + '][name]" value="" style="width:100%;" maxlength="100" required="required"></div>' +
                                      '</div>' + 
                                  '</div>' + 
                                  '<div>' +
                                    '<select name="item_options[' + token + '][product_pricing]"><option value="full_amount">' + $(this).attr('data-full_amount') + '</option><option value="deposit">' + $(this).attr('data-deposit') + '</option></select>' + 
                                  '</div>' + 
                                  '<div><button data-container="' + $(this).attr('data-container') + '" class="btnDel"><img src="/assets/graphics/svg/fa/remove.svg" width="16" height="16" alt="Remove" style="width: 16px;height: 16px;"></button></div>' + 
                               '</div>');
        }

    });

    $(document).on('click', '.btnDel', function(){

        $(this).parent().parent().remove();

        if($("#attendeesRowsWrapper > div").length < 1){
            $("#attendeesRowsWrapper").addClass('hidden');
            $(".addAttendeesToCart").addClass('hidden');
            $(".continueAdding").addClass('hidden');
            $(".attendeesHeaderLabelsWrapper").addClass('hidden');
            $(".addSomeAttendeesHeader").removeClass('hidden');
        }

    });

});

$(document).ready(function(){
    "use strict";

    var mywindow;

    function orderDetailsPopup(data){

        mywindow = window.open('', 'Order Details', 'height=800,width=700');
        mywindow.document.write('<html>' +
            '<head>' +
            '<title>Order Details</title>' +
            '<link href="/assets/css/all.min.css" rel="stylesheet" media="all">' +
            '</head>' +
            '<body style="padding:50px;">');
        mywindow.document.write(data);
        mywindow.document.write('</body>' +
            '</html>');
        return true;

    }

    $(".printOrder").on("click", function(e){
        e.preventDefault();
        var details = $('.printableOrderDetails').html();
        orderDetailsPopup(details);
        //mywindow.print();
        //mywindow.close();
    });

    /*
    ********************************
    * GENERAL FUNCTIONS 
    ********************************
    */

    /*
     * CLEAR SHIPPING FIELDS
     */
    function clearShippingFields(){
        $("#shipping_first_name").val('');
        $("#shipping_last_name").val('');
        $("#shipping_company").val('');
        $("#shipping_address").val('');
        $("#shipping_address2").val('');
        $("#shipping_city").val('');
        $("#shipping_state").val('');
        $("#shipping_province_territory").val('');
        $("#shipping_zip").val('');
        $("#shipping_country_code").val('');
    };

    /*
     * MAKE SHIPPING FIELDS REQUIRED
     */
    function makeShippingFieldsRequired(){
        $("#shipping_first_name").prop('required', true);
        $("#shipping_last_name").prop('required', true);
        $("#shipping_address").prop('required', true);
        $("#shipping_city").prop('required', true);
        //$("#shipping_state").prop('required', true);
        //$("#shipping_province_territory").prop('required', true);
        $("#shipping_zip").prop('required', true);
        $("#shipping_country_code").prop('required', true);
    };

    /*
     * REMOVE SHIPPING FIELDS REQUIRED
     */
    function removeShippingFieldsRequired(){
        $("#shipping_first_name").prop('required', false);
        $("#shipping_last_name").prop('required', false);
        $("#shipping_address").prop('required', false);
        $("#shipping_city").prop('required', false);
        //$("#shipping_state").prop('required', false);
        //$("#shipping_province_territory").prop('required', false);
        $("#shipping_zip").prop('required', false);
        $("#shipping_country_code").prop('required', false);
    };

    /*
    ********************************
    * EVENT HANDLERS
    ********************************
    */

    /*
     * CLEAR CART BUTTON
     */
    $('.clearCartButton').on('click', function(e){

        e.preventDefault();

        var url     = e && (('string' === typeof e && e) || (e.currentTarget && e.currentTarget.attributes.href.value));
        var title   = 'Clear Cart';
        var message = 'Remove all items from your cart?';

        $.confirm({
                      'title': title,
                      'message': message,
                      'buttons': {
                          'Yes': {
                              'class': 'confirmPositive',
                              'action': function(){
                                  if(url){
                                      window.location = url;
                                  } else {
                                      return false;
                                  }
                              }
                          },
                          'No': {
                              'default': true,
                              'class': 'confirmNegative',
                              'action': function(){
                                  return false;
                              }
                          }
                      }

                  });

    });

    /*
     * EDIT CART ITEM BUTTON
     */
    $('.editCartItemButton').on('click', function(e){

        e.preventDefault();

        var cartItemForm = $("#" + $(this).attr("data-form"));
        cartItemForm.find('.cartItemEditable').removeClass('disabled');
        
        // enable, remove disabled class, and set old value
        var editableTextInput = cartItemForm.find('.cartItemEditable input[type=text]');
        editableTextInput.each(function(){
            $(this).prop("disabled", false);
            $(this).removeClass('disabled');
            $(this).attr('data-old-value', $(this).val());
        });
        var editableTextarea = cartItemForm.find('.cartItemEditable textarea');
        editableTextarea.each(function(){
            $(this).prop("disabled", false);
            $(this).removeClass('disabled');
            $(this).attr('data-old-value', $(this).val());
        });
        var editableSelect = cartItemForm.find('.cartItemEditable select');
        editableSelect.each(function(){
            $(this).prop("disabled", false);
            $(this).removeClass('disabled');
            $(this).attr('data-old-value', $(this).val());
        });

        // hide all item edit/delete buttons
        var thisEdit = $('.cartEditRemove');
        thisEdit.animate({
            right: parseInt(thisEdit.css('right'),10) == 0 ? -170 : 0
        });
        
        // show this action buttons
        var thisUpdate = cartItemForm.find('.cartCancelUpdate');
        thisUpdate.animate({
            right: parseInt(thisUpdate.css('right'),10) == 0 ? 170 : 0
        });

    });

    /*
     * CANCEL EDIT CART ITEM BUTTON
     */
    $('.cancelCartItemButton').on('click', function(e){

        e.preventDefault();

        var cartItemForm = $("#" + $(this).attr("data-form"));
        cartItemForm.find('.cartItemEditable').addClass('disabled');
        
        // disable, add disabled class, and set old value
        var editableTextInput = cartItemForm.find('.cartItemEditable input[type=text]');
        editableTextInput.each(function(){
            $(this).prop("disabled", true);
            $(this).addClass('disabled');
            $(this).val($(this).attr('data-old-value'));
        });
        var editableTextarea = cartItemForm.find('.cartItemEditable textarea');
        editableTextarea.each(function(){
            $(this).prop("disabled", true);
            $(this).addClass('disabled');
            $(this).val($(this).attr('data-old-value'));
        });
        var editableSelect = cartItemForm.find('.cartItemEditable select');
        editableSelect.each(function(){
            $(this).prop("disabled", true);
            $(this).addClass('disabled');
            $(this).val($(this).attr('data-old-value'));
        });

        // hide all item edit/delete buttons
        var thisEdit = $('.cartEditRemove');
        thisEdit.animate({
            right: parseInt(thisEdit.css('right'),10) == 0 ? 170 : 0
        });
        
        // show this action buttons
        var thisUpdate = cartItemForm.find('.cartCancelUpdate');
        thisUpdate.animate({
            right: parseInt(thisUpdate.css('right'),10) == 0 ? -170 : 0
        });

    });
    
    /*
     * UPDATE CART BUTTON
     */
    $('.updateCartItemButton').on('click', function(e){
        e.preventDefault();
        document.body.style.cursor = 'wait';
        var cartForm               = $("#" + $(this).attr("data-form"));
        cartForm.submit();
    });

    /*
     * DELETE CART ITEM BUTTON
     */
    $('.deleteCartItemButton').on('click', function(e){

        e.preventDefault();

        var cartForm           = $("#" + $(this).attr("data-form"));
        var deleteItemCheckbox = $("#" + $(this).attr("data-delete"));

        var title   = 'Remove Item';
        var message = 'Remove item from your cart?';
        $.confirm({
                      'title': title,
                      'message': message,
                      'buttons': {
                          'Yes': {
                              'class': 'confirmPositive',
                              'action': function(){
                                  $(".cartDeleteItemCheckbox").prop('checked', false);
                                  $(deleteItemCheckbox).prop('checked', true);
                                  document.body.style.cursor = 'wait';
                                  cartForm.submit();
                              }
                          },
                          'No': {
                              'default': true,
                              'class': 'confirmNegative',
                              'action': function(){
                                  return false;
                              }
                          }
                      }
                  });

    });

    /*
     * COPY BILLING INFO TO SHIPPING BUTTON
     */
    $("#copyBillingInfo").on('click', function(e){

        e.preventDefault();

        $("#shipping_first_name").val($("#first_name").val());
        $("#shipping_last_name").val($("#last_name").val());
        $("#shipping_company").val($("#company").val());
        $("#shipping_address").val($("#address").val());
        $("#shipping_address2").val($("#address2").val());
        $("#shipping_city").val($("#city").val());
        $("#shipping_state").val($("#state").val());
        $("#shipping_province_territory").val($("#billing_province_territory").val());
        $("#shipping_zip").val($("#zip").val());
        $("#shipping_country_code").val($("#country_code").val());
        $("#shipping_email").val($("#customer_email_address").val());
        $("#shipping_phone").val($("#customer_phone").val());
        $("#shipping_country_code").val($("#country_code").val());
    });

    /*
     * INITIALIZE CART CONTENTS PAGE
     */
    function initCartContentsPage(){
        $('.cartItemEditable input[type=text]').prop("disabled", true).addClass('disabled');
        $('.cartItemEditable select').prop("disabled", true).addClass('disabled');
        $('.cartItemEditable textarea').prop("disabled", true).addClass('disabled');
    }

    if($('body').attr('id') === 'your-cart'){
        initCartContentsPage();
    }
    
    $('#checkoutForm').submit(function(){
        $("#submitOrderButton").prop("disabled", true).addClass('submitDisabled');
    });

});

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 86400));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
function eraseCookie(name) {   
    document.cookie = name+'=; Max-Age=date.getTime() - 86400;';  
}

$(document).ready(function(){

    function initIndexBanner(){
        var indexBannerAd = $('.indexBannerAd');
        var bannerHeight  = indexBannerAd.height();
        indexBannerAd.css('height', bannerHeight + 'px');
        indexBannerAd.css('bottom', '-' + bannerHeight + 'px');
        if(!getCookie('exp_seen_banner')){
            indexBannerAd.animate({
                                      bottom: "0"
                                  }, 500);
        }
    }

    $(document).on('click', '.closeBanner', function(){
        setCookie('exp_seen_banner', 'y', 86400);
        var indexBannerAd = $('.indexBannerAd');
        var bannerHeight  = indexBannerAd.height();
        indexBannerAd.animate({
                                  bottom: '-' + bannerHeight + 'px'
                              }, 200);
    });

    /* remove all but the first one */
    $('.indexBannerAd .bannerAdImageWrapper').slice(1).remove();
    setTimeout(initIndexBanner, 1500);

});


$(document).ready(function(){

    /*if(!('IntersectionObserver' in window)){*/

    /* unsupported, just load everything */
    /*
    $("[data-src]").each(function(){
        $(this).attr('srcset', $(this).data("srcset"));
        $(this).removeAttr("data-srcset");
        $(this).attr('src', $(this).data("src"));
        $(this).removeAttr("data-src");
    });
    */

    /*} else {*/

    function preloadImage(img){
        var src    = img.getAttribute('data-src');
        var srcset = img.getAttribute('data-srcset');
        if(!src){
            return;
        }

        img.srcset = srcset;
        img.src    = src;
        img.onload = function(){
            img.removeAttribute('data-src');
            img.removeAttribute('data-srcset');
        };

    }
    
    var observer = new IntersectionObserver(function(entries, self){
        entries.forEach(function(entry){
            if(entry.isIntersecting){
                preloadImage(entry.target);
                self.unobserve(entry.target);
            }
        });
    }, {
        rootMargin: '0px 0px 50px 0px',
        threshold: 0
        });

    $("img[data-src]").each(function(){
        observer.observe(this);
    });

    /*}*/

});

$(document).ready(function(){

    /*
     *  REQUIRED for responsive menu
     *  add button to open submenus
     *  ****************************
     */
    $('#mobileNavigation .mobileMainMenu li').each(function(){
        if($(this).children('ul').length > 0){
            $(this).children(":first").prepend('<span class="openSubMenu"></span>');
            $(this).addClass('hasChild');
        }
    });
    
    $(document).on('click', '.mobileMainMenu .openSubMenu', function(e){

        e.preventDefault();

        var parentLi = $(this).closest("li");
        var auntLi = parentLi.siblings("li");

        // close submenus
        auntLi.find("ul").slideUp();
        parentLi.find("ul").slideUp();
        
        if(!parentLi.find("ul:first").is(":visible")){
            auntLi.find(".subMenuOpen").removeClass('subMenuOpen');
            $(this).addClass('subMenuOpen');
            parentLi.find("ul:first").slideDown();
        } else {
            parentLi.find(".subMenuOpen").removeClass('subMenuOpen');
        }
        
    });
    
});

$(document).ready(function(){

    function fixIeObjectFit(){

        $('.objectFitImage').each(function(){
            var img    = $(this).find('img');
            var imgURL = img.attr('src');
            if(!imgURL){
                imgURL = img.attr('data-src');
            }
            img.hide();
            $(this).css('background-image', 'url(' + imgURL + ')');
            $(this).css('background-size', 'cover');
            $(this).css('background-position', 'center center');
        });

    }

    // Detect objectFit support
    if(document.createElement("detect").style.objectFit === ""){
        return;

    } else {
        fixIeObjectFit();

    }

});

